import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="thankyou">
      <div className="container-info">
        <h2>हमारी दिव्य यात्रा:</h2>
        <p>
          प्रसाद बुक करें पर, हम भक्तों को वैष्णो देवी के दिव्य आशीर्वाद से जोड़ने की
          एक पवित्र यात्रा पर हैं। हमारी कहानी विश्वास, परंपरा, और वैष्णो देवी के
          आध्यात्मिक अनुभव को सीधे आपके द्वार तक लाने के लिए गहरी प्रतिबद्धता से
          बुनी गई है।
        </p>
      </div>
      <div className="container-info">
        <h2>परंपरा में हमारी जड़ें:</h2>
        <p>
          हमारी संस्कृति की समृद्ध परंपराओं से प्रेरित, प्रसाद बुक करें एक प्यार
          भरा प्रयास है जो एक सरल विचार से शुरू हुआ — वैष्णो देवी के पवित्र
          प्रसाद को हर किसी तक पहुँचाना, भले ही वे कहीं भी हों।
        </p>
      </div>
      <div className="container-info">
        <h2>शुद्धता के प्रति हमारी प्रतिबद्धता:</h2>
        <p>
          हम प्रसाद की पवित्रता को समझते हैं और अपने प्रसाद में शुद्धता के उच्चतम
          मानकों का पालन करते हैं। हर प्रसाद पैक को बड़ी सावधानी और भक्ति के साथ
          तैयार किया जाता है, ताकि आपको सबसे पवित्र रूप में दिव्य आशीर्वाद मिले।
        </p>
      </div>
      <div className="container-info">
        <h2>पर्यावरणीय जिम्मेदारी:</h2>
        <p>
          पर्यावरण के प्रति हमारी प्रतिबद्धता हमारी भक्ति जितनी ही मजबूत है। हम
          इको-फ्रेंडली पैकेजिंग का उपयोग करते हैं, ताकि हमारी सेवा स्थिरता और
          प्रकृति मां के प्रति सम्मान के मूल्यों के अनुरूप हो।
        </p>
      </div>
      <div className="container-info">
        <h2>विश्वभर के भक्तों को जोड़ना:</h2>
        <p>
          सीमाओं और समय क्षेत्रों से परे, हम एक वैश्विक समुदाय बनाने का प्रयास करते
          हैं, जहां भक्त वैष्णो देवी की कृपा अपने घरों में अनुभव कर सकें। प्रसाद
          बुक करें केवल एक सेवा नहीं है; यह दिलों को दिव्य से जोड़ने का एक सेतु है।
        </p>
      </div>
      <div className="container-info">
        <h2>ग्राहक-केंद्रित दृष्टिकोण:</h2>
        <p>
          आपकी संतुष्टि हमारे मिशन का केंद्र है। हमारी ग्राहक सेवा टीम आपको
          सहज और आनंदमय अनुभव प्रदान करने के लिए समर्पित है। यदि आपको कोई सहायता
          चाहिए, तो हमसे संपर्क करें; हम आपकी सेवा में हर समय तैयार हैं।
        </p>
      </div>
      <div className="container-info">
        <h2>इस आध्यात्मिक यात्रा में हमारे साथ जुड़ें:</h2>
        <p>
          चाहे आप एक श्रद्धालु अनुयायी हों या जिज्ञासु साधक, हम आपको इस आध्यात्मिक
          यात्रा में हमारे साथ जुड़ने के लिए आमंत्रित करते हैं। प्रसाद बुक करें
          केवल एक सेवा नहीं है; यह एक ऐसा समुदाय है जो विश्वास, प्रेम, और दिव्य
          आशीर्वाद की खोज से एकजुट है।
        </p>
      </div>
      <div className="container-info">
        <h2>आपकी यात्रा शुभ हो:</h2>
        <p>
          जैसे ही आप हमारे प्लेटफॉर्म की पेशकशों का पता लगाते हैं, आपकी यात्रा
          वैष्णो देवी की दिव्य ऊर्जा से भरपूर हो। इस पवित्र मार्ग पर आपके साथी
          के रूप में प्रसाद बुक करें को चुनने के लिए धन्यवाद।
        </p>
        <Link style={{ textDecoration: "none" }} to="/productpage">
          <div className="order-now-btn-1">हमारा प्रसाद देखें</div>
        </Link>
      </div>
    </div>
  );
};

export default About;
