import p3_img from "./ganga.jpg";
import p2_img from "./prasad.jpg";
import p1_img from "./mahakalp.jpeg";
import p4_img from "./babadham-prasadi.jpg";
import ramp_img from "./ramp.avif"
import rugd from "./rug.jpg"

let data_product = [
  {
    id: 1,
    name: "माँ वैष्णो देवी प्रसाद",
    image: p2_img,
    new_price: 499,
    old_price: 700,
  },
  {
    id: 2,
    name: "बाबा धाम प्रसाद",
    image: p4_img,
    new_price: 599,
    old_price: 899,
  },
  {
    id: 3,
    name: "उज्जैन महाकाल प्रसाद",
    image: p1_img,
    new_price: 699,
    old_price: 1249,
  },

  {
    id: 4,
    name: "राम मंदिर प्रसाद",
    image: ramp_img,
    new_price: 699,
    old_price: 1249,
  },
  {
    id: 5,
    name: "गंगोत्री गंगाजल",
    image: p3_img,
    new_price: 699,
    old_price: 1249,
  },
  {
    id: 6,
    name: "रुद्राक्ष माला",
    image: rugd,
    new_price: 699,
    old_price: 1249,
  },
];

export default data_product;
